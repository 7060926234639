import React, { useState } from "react";
import "./LoginPage.css";
import { CiUser, CiLock } from "react-icons/ci";
import churchLogo from "../../assets/logo.jpg";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    console.log("Logging in with", { username, password });
  };

  const handleForgotPassword = () => {
    console.log("Forgot password clicked");
  };

  return (
    <>
      <section className="container">
        <header className="header">
          <img className="logo" src={churchLogo} alt="Logo" />
          <h1 className="text">Login</h1>
        </header>
        <form className="inputs">
          <div className="input">
            <CiUser className="img" />
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
              required
              autoComplete="username"
            />
          </div>
          <div className="input">
            <CiLock className="img" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
              autoComplete="current-password"
            />
          </div>
        </form>
        <div className="submit-container">
          <button className="login" onClick={handleLogin}>
            Login
          </button>
        </div>
        <footer>
          <p className="forgot-password">
            Forgot password? <button className="link" onClick={handleForgotPassword}>Click Here</button>
          </p>
          <p className="register">
            <a href="/register">Register</a>
          </p>
        </footer>
      </section>
    </>
  );
}

export default LoginPage;
