import './App.css';
import LoginPage from '../src/components/Login/LoginPage';

function App() {
  return (
    <div>
      <LoginPage />
    </div>
  );
}

export default App;
